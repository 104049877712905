import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { client } from '../../client';

const ViewUser = () => {
	const [user, setUser] = useState({});
	const params = useParams();

	useEffect(() => {
		(async () => {
			const users = await client.service('users').find({
				query: {
					_id: params.id,
				},
			});

			const [user] = users.data;

			setUser(user);
		})();
	}, []);

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/users'>
					Users
				</Link>{' '}
				{'>'} View User
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4 w-1/2'>
				<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
					<div className='w-3/12 xl:w-2/12 text-sm font-bold'>User ID:</div>
					<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
						{user._id}
					</div>
				</div>
				<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
					<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Name:</div>
					<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
						{user.name}
					</div>
				</div>
				<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
					<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Email ID:</div>
					<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
						{user.email}
					</div>
				</div>
				<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
					<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Role:</div>
					<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
						{user.role}
					</div>
				</div>
				<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
					<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Status:</div>
					<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
						{user.status}
					</div>
				</div>
				<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
					<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Created At:</div>
					<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
						{moment(user.createdAt).format('LLL')}
					</div>
				</div>
				<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
					<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Updated At:</div>
					<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
						{moment(user.updatedAt).format('LLL')}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewUser;
