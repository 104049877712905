import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { client } from '../../client';

const ViewProduct = () => {
	const [product, setProduct] = useState({});
	const params = useParams();

	useEffect(() => {
		(async () => {
			const products = await client.service('products').find({
				query: {
					_id: params.id,
				},
			});

			const [product] = products.data;

			setProduct(product);
		})();
	}, []);

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/products'>
					Products
				</Link>{' '}
				{'>'} View Product
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4'>
				<div className='flex flex-col md:flex-row'>
					<div className='w-full md:w-1/2'>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>
								Product ID:
							</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{product._id}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Name:</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{product.name}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Volume:</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{product.classification}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Packing:</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{product.packing}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>
								Category:
							</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{product.category}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>Stars:</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{product.stars}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>
								Description:
							</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{product.description}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>
								Available:
							</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{product.available}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>
								Created At:
							</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{moment(product.createdAt).format('LLL')}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 xl:w-2/12 text-sm font-bold'>
								Updated At:
							</div>
							<div className='w-9/12 xl:w-10/12 md:ml-4 text-sm text-gray-700'>
								{moment(product.updatedAt).format('LLL')}
							</div>
						</div>
					</div>
					<div className='flex justify-center items-center w-full md:w-1/2'>
						<img
							className='shadow-md mt-5 md:mt-0 w-1/2 md:w-3/4 xl:w-1/2'
							src={`${product.imageUrl}-500x500.jpg`}
							alt={product.name}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewProduct;
