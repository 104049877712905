import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchOrderCountAsync } from '../../redux/order/order.actions';
import { fetchProductCountAsync } from '../../redux/product/product.actions';
import { fetchUserCountAsync } from '../../redux/user/user.actions';
import { selectOrderCount } from '../../redux/order/order.selectors';
import { selectProductCount } from '../../redux/product/product.selectors';
import { selectUserCount } from '../../redux/user/user.selectors';

const Dashboard = ({
	_fetchOrderCountAsync,
	_fetchProductCountAsync,
	_fetchUserCountAsync,
	orderCount,
	productCount,
	userCount,
}) => {
	useEffect(() => {
		_fetchOrderCountAsync();
		_fetchProductCountAsync();
		_fetchUserCountAsync();
	}, []);

	return (
		<div className='container flex-grow'>
			<div className='flex flex-col w-full lg:flex-row justify-between mt-24 lg:mt-64'>
				<Link to='/orders/list' className='w-full lg:w-1/3'>
					<div className='flex justify-between lg:mr-6 xl:mr-12 bg-teal-500 dashboard-item'>
						<svg
							className='w-20 h-20'
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='1'
								d='M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z'
							/>
						</svg>
						<div className='flex flex-col justify-between'>
							<div className='font-bold text-4xl'>{orderCount}</div>
							<div>Orders</div>
						</div>
					</div>
				</Link>
				<Link to='/products' className='w-full lg:w-1/3'>
					<div className='flex justify-between lg:mr-6 xl:mr-12 bg-indigo-500 dashboard-item'>
						<svg
							className='w-20 h-20'
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='1'
								d='M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z'
							/>
						</svg>
						<div className='flex flex-col justify-between'>
							<div className='font-bold text-4xl'>{productCount}</div>
							<div>Products</div>
						</div>
					</div>
				</Link>
				<Link to='/users' className='w-full lg:w-1/3'>
					<div className='flex justify-between bg-orange-500 dashboard-item'>
						<svg
							className='w-20 h-20'
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='1'
								d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
							/>
						</svg>
						<div className='flex flex-col justify-between'>
							<div className='font-bold text-4xl'>{userCount}</div>
							<div>Users</div>
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	orderCount: selectOrderCount,
	productCount: selectProductCount,
	userCount: selectUserCount,
});

const mapDispatchToProps = (dispatch) => ({
	_fetchOrderCountAsync: () => dispatch(fetchOrderCountAsync()),
	_fetchProductCountAsync: () => dispatch(fetchProductCountAsync()),
	_fetchUserCountAsync: () => dispatch(fetchUserCountAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
