import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import { editCourierAsync } from '../../redux/courier/courier.actions';

import { client } from '../../client';

const EditCourier = ({ _editCourierAsync }) => {
	const [name, setName] = useState('');
	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			const couriers = await client.service('couriers').find({
				query: {
					_id: params.id,
				},
			});

			const [courier] = couriers.data;

			setName(courier.name);
		})();
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case 'name':
				setName(value);
				break;
			default:
				return;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = {
			name,
		};

		_editCourierAsync(params.id, data);

		history.push('/couriers');
	};

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/couriers'>
					Couriers
				</Link>{' '}
				{'>'} Edit Courier
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4 w-1/2'>
				<form onSubmit={handleSubmit}>
					<div className='flex flex-col'>
						<div className='flex flex-col w-full mr-2'>
							<label className='text-sm font-bold' htmlFor='name'>
								Courier Name:
							</label>
							<input
								type='text'
								name='name'
								id='name'
								value={name}
								onChange={handleChange}
								required
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
						</div>
						<div className='flex justify-center mt-6'>
							<button
								type='submit'
								className='w-1/4 p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
								Save
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	_editCourierAsync: (_id, data) => dispatch(editCourierAsync(_id, data)),
});

export default connect(null, mapDispatchToProps)(EditCourier);
