import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import { editProductAsync } from '../../redux/product/product.actions';

import { client } from '../../client';

const EditProduct = ({ _editProductAsync }) => {
	const [name, setName] = useState('');
	const [volume, setVolume] = useState('');
	const [category, setCategory] = useState('');
	const [stars, setStars] = useState('');
	const [image, setImage] = useState('');
	const [description, setDescription] = useState('');
	const [available, setAvailable] = useState('');
	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			const products = await client.service('products').find({
				query: {
					_id: params.id,
				},
			});

			const [product] = products.data;
			const imageUrl = product.imageUrl.split('/');
			const [image] = imageUrl.slice(-1);

			setName(product.name);
			setVolume(product.classification);
			setCategory(product.category);
			setStars(product.stars);
			setImage(image);
			setDescription(product.description);
			setAvailable(product.available);
		})();
	}, []);

	const handleChange = (event) => {
		const { name, value } = event.target;

		switch (name) {
			case 'name':
				setName(value);
				break;
			case 'volume':
				setVolume(value);
				break;
			case 'category':
				setCategory(value);
				break;
			case 'stars':
				setStars(value);
				break;
			case 'image':
				setImage(value);
				break;
			case 'description':
				setDescription(value);
				break;
			case 'available':
				setAvailable(value);
				break;
			default:
				return;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		await _editProductAsync(params.id, {
			name,
			category,
			stars,
			description,
			classification: volume,
			imageUrl: `${process.env.REACT_APP_STATIC}/products/${image}/${image}`,
			available,
		});

		history.push('/products');
	};

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/products'>
					Products
				</Link>{' '}
				{'>'} Add Product
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4'>
				<form onSubmit={handleSubmit}>
					<div className='flex'>
						<div className='flex flex-col w-1/2 mr-2'>
							<label htmlFor='name'>Name:</label>
							<input
								type='text'
								name='name'
								id='name'
								value={name}
								onChange={handleChange}
								required
								className='py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
							<label htmlFor='volume'>Volume:</label>
							<select
								name='volume'
								id='volume'
								value={volume}
								onChange={handleChange}
								required
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 bg-white mb-4 h-8'>
								<option className='border-gray-500' value=''>
									Please Select Value
								</option>
								<option className='border-gray-500' value='none'>
									NONE
								</option>
								<option className='border-gray-500' value='DRUM'>
									DRUM
								</option>
								<option className='border-gray-500' value='PAIL'>
									PAIL
								</option>
								<option className='border-gray-500' value='1GALLON'>
									1GALLON
								</option>
								<option className='border-gray-500' value='1LITER'>
									1LITER
								</option>
								<option className='border-gray-500' value='500GRAMS'>
									500GRAMS
								</option>
								<option className='border-gray-500' value='210GRAMS'>
									210GRAMS
								</option>
								<option className='border-gray-500' value='800ML'>
									800ML
								</option>
								<option className='border-gray-500' value='TUBE'>
									TUBE
								</option>
							</select>
							<label htmlFor='category'>Category:</label>
							<select
								name='category'
								id='category'
								value={category}
								onChange={handleChange}
								required
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 bg-white mb-4 h-8'>
								<option className='border-gray-500' value=''>
									Please select value
								</option>
								<option className='border-gray-500' value='Motorcycle Oils'>
									Motorcycle Oils
								</option>
								<option
									className='border-gray-500'
									value='Gasoline Engine Oils'>
									Gasoline Engine Oils
								</option>
								<option className='border-gray-500' value='Diesel Engine Oils'>
									Diesel Engine Oils
								</option>
								<option className='border-gray-500' value='Gear Oils'>
									Gear Oils
								</option>
								<option className='border-gray-500' value='Hydraulic Oils'>
									Hydraulic Oils
								</option>
								<option className='border-gray-500' value='Transmission Fluid'>
									Transmission Fluid
								</option>
								<option className='border-gray-500' value='Automotive Grease'>
									Automotive Grease
								</option>
								<option className='border-gray-500' value='Welding Rod'>
									Welding Rod
								</option>
							</select>
							<label htmlFor='stars'>Stars:</label>
							<select
								name='stars'
								id='stars'
								value={stars}
								onChange={handleChange}
								required
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 bg-white mb-4 h-8'>
								<option className='border-gray-500' value=''>
									Please select value
								</option>
								<option className='border-gray-500' value='5'>
									5
								</option>
								<option className='border-gray-500' value='4'>
									4
								</option>
							</select>
						</div>
						<div className='flex flex-col w-1/2 ml-2'>
							<label className='text-sm font-bold' htmlFor='available'>
								Available:
							</label>
							<select
								name='available'
								id='available'
								value={available}
								onChange={handleChange}
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 bg-white mb-4 h-8'>
								<option className='border-gray-500' value='yes'>
									yes
								</option>
								<option className='border-gray-500' value='no'>
									no
								</option>
							</select>
							<label htmlFor='image'>Image:</label>
							<input
								type='text'
								name='image'
								id='image'
								value={image}
								onChange={handleChange}
								placeholder='e.g. p-0001'
								required
								className='py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
							<label htmlFor='description'>Description:</label>
							<textarea
								name='description'
								id='description'
								value={description}
								onChange={handleChange}
								required
								className=' resize-none border border-gray-500 w-full h-40 rounded-lg focus:outline-none p-2 md:p-3 text-gray-700 text-xs sm:text-sm md:text-base'
							/>
						</div>
					</div>
					<div className='flex justify-center mt-6'>
						<button
							type='submit'
							className='w-1/4 p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
							Save
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	_editProductAsync: (_id, data) => dispatch(editProductAsync(_id, data)),
});

export default connect(null, mapDispatchToProps)(EditProduct);
