import { courierActionTypes } from './courier.types';
import { client } from '../../client';

const fetchCouriersStart = () => ({
	type: courierActionTypes.FETCH_COURIERS_START,
});

const setCouriers = (couriers) => ({
	type: courierActionTypes.SET_COURIERS,
	payload: couriers,
});

export const fetchCouriersAsync = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchCouriersStart());
			const couriers = await client.service('couriers').find({
				query: {
					$paginate: { value: false },
					$sort: {
						name: 1,
					},
				},
			});

			await dispatch(setCouriers(couriers));
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const addCourierAsync = (data) => {
	return async (dispatch) => {
		try {
			await client.service('couriers').create(data);
			dispatch(fetchCouriersAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const editCourierAsync = (_id, data) => {
	return async (dispatch) => {
		try {
			await client.service('couriers').patch(_id, data);
			dispatch(fetchCouriersAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const deleteCourierAsync = (courier_id) => {
	return async (dispatch) => {
		try {
			await client.service('couriers').remove(courier_id);
			dispatch(fetchCouriersAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};
