import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { client } from '../../client';

const ConfirmOrder = () => {
	const params = useParams();
	const history = useHistory();

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			await client.service('orders').patch(params.id, {
				confirmedAt: new Date(),
				status: 'confirmed',
			});

			history.push('/orders/list');
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/orders/list'>
					Orders
				</Link>{' '}
				{'>'} Confirm Order
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4 w-1/2'>
				<p className='text-xs italic mb-2'>
					Note: Fields are disabled to avoid unintentional change of data.
				</p>
				<form onSubmit={handleSubmit}>
					<div className='flex flex-col'>
						<label className='text-sm font-bold' htmlFor='orderId'>
							Order ID:
						</label>
						<input
							type='text'
							name='orderId'
							id='orderId'
							value={params.id}
							required
							disabled
							className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
						/>
						<label className='text-sm font-bold' htmlFor='confirmedAt'>
							Date Confirmed:
						</label>
						<input
							type='text'
							name='confirmedAt'
							id='confirmedAt'
							value={moment().format('LLL')}
							required
							disabled
							className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
						/>
						<label className='text-sm font-bold' htmlFor='status'>
							Status:
						</label>
						<input
							type='text'
							name='status'
							id='status'
							value='confirmed'
							required
							disabled
							className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
						/>
						<div className='flex justify-center mt-6'>
							<button
								type='submit'
								className='w-1/4 p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
								Save
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ConfirmOrder;
