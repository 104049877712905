import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { createUserAsync } from '../../redux/user/user.actions';

const AddUser = ({ _createUserAsync }) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const history = useHistory();

	const handleChange = (event) => {
		const { name, value } = event.target;

		switch (name) {
			case 'name':
				setName(value);
				break;
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			default:
				return;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const role = 'administrator';
		const status = 'active';

		await _createUserAsync({
			name,
			email,
			password,
			role,
			status,
			plainPassword: password,
		});

		history.push('/users');
	};

	const handleGenerate = () => {
		const generatePassword = () => {
			const length = 8;
			const charset =
				'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
			let retVal = '';
			let i;
			let n;

			for (i = 0, n = charset.length; i < length; ++i) {
				retVal += charset.charAt(Math.floor(Math.random() * n));
			}

			return retVal;
		};

		setPassword(generatePassword());
	};

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/users'>
					Users
				</Link>{' '}
				{'>'} Add User
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4'>
				<form onSubmit={handleSubmit}>
					<div className='flex'>
						<div className='flex flex-col w-1/2 mr-2'>
							<label htmlFor='name'>Full Name:</label>
							<input
								type='text'
								name='name'
								id='name'
								value={name}
								onChange={handleChange}
								required
								className='py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
							<label htmlFor='email'>Email:</label>
							<input
								type='email'
								name='email'
								id='email'
								value={email}
								onChange={handleChange}
								required
								className='py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
							<div className='flex flex-col'>
								<label htmlFor='password'>Password:</label>
								<div className='flex items-center'>
									<input
										type='text'
										name='password'
										id='password'
										value={password}
										minLength='6'
										onChange={handleChange}
										required
										className='py-1 px-2 outline-none rounded-md border border-gray-500 w-11/12'
									/>
									<button
										type='button'
										className='flex justify-center bg-gray-400 p-2 rounded-sm focus:outline-none -ml-1 w-1/12'
										onClick={handleGenerate}>
										<svg
											className='w-5 h-5'
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
											/>
										</svg>
									</button>
								</div>
							</div>
						</div>
						<div className='flex flex-col w-1/2 ml-2'>
							<label htmlFor='role'>Role:</label>
							<input
								type='text'
								name='role'
								id='role'
								value='administrator'
								disabled
								className='py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
							<label htmlFor='status'>Status:</label>
							<input
								type='type'
								name='status'
								id='status'
								value='active'
								disabled
								className='py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
						</div>
					</div>
					<div className='flex justify-center mt-6'>
						<button
							type='submit'
							className='w-1/4 p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
							Submit
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	_createUserAsync: (data) => dispatch(createUserAsync(data)),
});

export default connect(null, mapDispatchToProps)(AddUser);
