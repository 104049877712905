import React from 'react';

const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
	const pageNumbers = [];

	for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
		pageNumbers.push(i);
	}

	return (
		<nav>
			<ul className='flex'>
				{pageNumbers.map((number) => (
					<li
						key={number}
						className={`${
							currentPage === number
								? 'bg-blue-500 text-white'
								: 'text-gray-500  hover:bg-blue-200'
						}  border-collapse py-1 px-2 cursor-pointer rounded-sm`}
						onClick={() => paginate(number)}>
						<div>{number}</div>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Pagination;
