import { createSelector } from 'reselect';

const selectOrder = (state) => state.order;

export const selectOrderCount = createSelector(
	[selectOrder],
	(order) => order.orderCount
);

export const selectOrders = createSelector(
	[selectOrder],
	(orders) => orders.orderItems
);

export const selectArchivedOrders = createSelector(
	[selectOrder],
	(orders) => orders.archivedOrderItems
);
