import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import Pagination from '../../components/pagination/pagination.component';
import { fetchArchivedOrdersAsync } from '../../redux/order/order.actions';
import { selectArchivedOrders } from '../../redux/order/order.selectors';

const OrdersArchived = ({ _fetchArchivedOrdersAsync, archivedOrders }) => {
	const [searchField, setSearchField] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [archivedOrdersPerPage] = useState(10);
	const filteredArchivedOrders = archivedOrders.filter((order) => {
		return order._id.toLowerCase().includes(searchField.toLowerCase());
	});

	useEffect(() => {
		(async () => {
			await _fetchArchivedOrdersAsync();
		})();
	}, []);

	const indexOfLastOrder = currentPage * archivedOrdersPerPage;
	const indexOfFirstOrder = indexOfLastOrder - archivedOrdersPerPage;
	const currentArchivedOrders = filteredArchivedOrders.slice(
		indexOfFirstOrder,
		indexOfLastOrder
	);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleChange = (e) => {
		setSearchField(e.target.value);
		setCurrentPage(1);
	};

	return (
		<div className='flex-grow py-4 px-10 md:px-20'>
			<div className='text-teal-500 font-bold text-3xl mb-2'>
				Completed Orders
			</div>
			<div className='bg-white rounded-sm shadow-lg p-3'>
				<div className='flex justify-between mt-3 mb-6'>
					<div>{''}</div>
					<div className='flex items-center'>
						<label htmlFor='search' className='mr-2'>
							Search:
						</label>
						<input
							className='py-1 px-2 outline-none rounded-md border border-gray-500'
							type='text'
							id='search'
							onChange={handleChange}
							placeholder='Order ID'
						/>
					</div>
				</div>
				<table className='table-fixed border-collapse w-full text-sm md:text-base'>
					<thead className='bg-gray-300'>
						<tr className='text-left'>
							<th className='p-2'>Order ID</th>
							<th className='p-2'>Customer Name</th>
							<th className='p-2 w-40'>Cart Items</th>
							<th className='p-2 w-40'>Status</th>
							<th className='p-2'>Courier</th>
							<th className='p-2'>Processed At</th>
							<th className='p-2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{currentArchivedOrders.map((order, index) => {
							return (
								<tr key={index} className='tr text-sm'>
									<td className='p-2'>{order._id}</td>
									<td className='p-2 text-blue-700'>
										<Link to={`/users/view/${order.user_id}`}>
											{order.user_name}
										</Link>
									</td>
									<td className='p-2'>{order.cartItems.length}</td>
									<td className='p-2'>
										<mark
											className={`
												${order.status === 'delivered' ? 'bg-green-600' : ''} 
												${order.status === 'rejected' ? 'bg-red-600' : ''} 
											text-white p-2 w-auto italic rounded-md`}>
											{order.status}
										</mark>
									</td>
									<td className='p-2'>
										{order.courier.name ? order.courier.name : ''}
									</td>
									<td className='p-2'>{moment(order.processedAt).fromNow()}</td>
									<td className='flex p-2'>
										<Link to={`/orders/view/${order._id}`}>
											<button className='bg-gray-400 px-2 rounded-sm focus:outline-none w-16'>
												View
											</button>
										</Link>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<div className='flex justify-between text-sm md:text-base mt-6'>
					<div>Total Record: {archivedOrders.length}</div>
					<Pagination
						rowsPerPage={archivedOrdersPerPage}
						totalRows={archivedOrders.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	archivedOrders: selectArchivedOrders,
});

const mapDispatchToProps = (dispatch) => ({
	_fetchArchivedOrdersAsync: () => dispatch(fetchArchivedOrdersAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersArchived);
