import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { client } from '../../client';

import 'react-datepicker/dist/react-datepicker.css';

const ShipOrder = () => {
	const [shippedAt, setShippedAt] = useState(new Date());
	const [courier, setName] = useState('');
	const [waybillNumber, setWaybillNumber] = useState('');
	const [vessel, setVessel] = useState('');
	const [voyageNumber, setVoyageNumber] = useState('');
	const [couriers, setCouriers] = useState([]);
	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			try {
				const couriers = await client.service('couriers').find({
					query: {
						$paginate: { value: false },
						$sort: {
							name: 1,
						},
					},
				});

				setCouriers(couriers);
			} catch (e) {
				console.log(e); // TODO: handle laters
			}
		})();
	}, []);

	const handleChange = (event) => {
		const { name, value } = event.target;

		switch (name) {
			case 'courier':
				setName(value);
				break;
			case 'waybillNumber':
				setWaybillNumber(value);
				break;
			case 'vessel':
				setVessel(value);
				break;
			case 'voyageNumber':
				setVoyageNumber(value);
				break;
			default:
				return;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			await client.service('orders').patch(params.id, {
				shippedAt: shippedAt,
				status: 'shipped',
				courier: {
					name: courier,
					waybillNumber,
					vessel,
					voyageNumber,
				},
			});

			history.push('/orders/list');
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/orders/list'>
					Orders
				</Link>{' '}
				{'>'} Ship Order
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4'>
				<p className='text-xs italic mb-2'>
					Note: Some fields are disabled to avoid unintentional change of data.
				</p>
				<form onSubmit={handleSubmit}>
					<div className='flex'>
						<div className='flex flex-col w-1/2 mr-2'>
							<div className='flex flex-col'>
								<label className='text-sm font-bold' htmlFor='orderId'>
									Order ID:
								</label>
								<input
									type='text'
									name='orderId'
									id='orderId'
									value={params.id}
									required
									disabled
									className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
								/>
								<label className='text-sm font-bold' htmlFor='shippedAt'>
									Date Shipped:
								</label>
								<DatePicker
									selected={shippedAt}
									onChange={(date) => setShippedAt(date)}
									className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4 w-full'
								/>
								<label className='text-sm font-bold' htmlFor='status'>
									Status:
								</label>
								<input
									type='text'
									name='status'
									id='status'
									value='shipped'
									required
									disabled
									className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
								/>
							</div>
						</div>
						<div className='flex flex-col w-1/2 ml-2'>
							<div className='flex flex-col'>
								<label className='text-sm font-bold' htmlFor='courier'>
									Courier Name:
								</label>
								<select
									name='courier'
									id='courier'
									value={courier}
									onChange={handleChange}
									required
									className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 bg-white mb-4 h-8'>
									<option className='border-gray-500' value=''>
										Please Select
									</option>
									{couriers &&
										couriers.map((courier, index) => {
											return (
												<option
													key={index}
													className='border-gray-500'
													value={courier.name}>
													{courier.name}
												</option>
											);
										})}
								</select>
								<label className='text-sm font-bold' htmlFor='waybillNumber'>
									Waybill No.:
								</label>
								<input
									type='text'
									name='waybillNumber'
									id='waybillNumber'
									value={waybillNumber}
									onChange={handleChange}
									required
									className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
								/>
								<label className='text-sm font-bold' htmlFor='vessel'>
									Vessel:
								</label>
								<input
									type='text'
									name='vessel'
									id='vessel'
									value={vessel}
									onChange={handleChange}
									placeholder='optional'
									className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
								/>
								<label className='text-sm font-bold' htmlFor='voyageNumber'>
									Voyage No.:
								</label>
								<input
									type='text'
									name='voyageNumber'
									id='voyageNumber'
									value={voyageNumber}
									onChange={handleChange}
									placeholder='optional'
									className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
								/>
							</div>
						</div>
					</div>
					<div className='flex justify-center mt-6'>
						<button
							type='submit'
							className='w-1/4 p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
							Save
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ShipOrder;
