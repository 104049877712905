import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useState } from 'react';
import moment from 'moment';

import Pagination from '../../components/pagination/pagination.component';
import {
	fetchProductsAsync,
	deleteProductAsync,
} from '../../redux/product/product.actions';
import { selectProducts } from '../../redux/product/product.selectors';

const Products = ({ products, _fetchProductsAsync, _deleteProductAsync }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteName, setDeleteName] = useState('');
	const [deleteId, setDeleteId] = useState('');
	const [searchField, setSearchField] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [productsPerPage] = useState(10);
	const filteredProducts = products.filter((product) => {
		return product.name.toLowerCase().includes(searchField.toLowerCase());
	});

	useEffect(() => {
		(async () => {
			await _fetchProductsAsync();
		})();
	}, []);

	const indexOfLastProduct = currentPage * productsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
	const currentProducts = filteredProducts.slice(
		indexOfFirstProduct,
		indexOfLastProduct
	);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleChange = (e) => {
		setSearchField(e.target.value);
		setCurrentPage(1);
	};

	const handleDelete = async () => {
		await _deleteProductAsync(deleteId);
		setShowDeleteModal(false);
	};

	return (
		<div className='flex-grow py-4 px-10 md:px-20'>
			<div className='text-indigo-500 font-bold text-3xl mb-2'>Products</div>
			<div className='bg-white rounded-sm shadow-lg p-3'>
				<div className='flex justify-between mt-3 mb-6'>
					<Link to='/products/add'>
						<button className='p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
							Add Product
						</button>
					</Link>
					<div className='flex items-center'>
						<label htmlFor='search' className='mr-2'>
							Search:
						</label>
						<input
							className='py-1 px-2 outline-none rounded-md border border-gray-500'
							type='text'
							id='search'
							onChange={handleChange}
							placeholder='Product Name'
						/>
					</div>
				</div>
				<table className='table-fixed border-collapse w-full text-sm md:text-base'>
					<thead className='bg-gray-300'>
						<tr className='text-left'>
							<th className='p-2 w-56'>Product ID</th>
							<th className='p-2 w-32'>Image</th>
							<th className='p-2'>Name</th>
							<th className='p-2 w-40'>Packing</th>
							<th className='p-2 w-40'>Category</th>
							<th className='p-2 w-40'>Available</th>
							<th className='p-2 w-40'>Created At</th>
							<th className='p-2 w-40'>Updated At</th>
							<th className='p-2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{currentProducts.map((product, index) => {
							return (
								<tr key={index} className='tr text-sm'>
									<td className='p-2'>{product._id}</td>
									<td className='p-2'>
										<img
											className=' w-10 h-10 shadow-md'
											src={`${product.imageUrl}-300x300.jpg`}
											alt={product.name}
										/>
									</td>
									<td className='p-2'>{product.name}</td>
									<td className='p-2'>{product.packing}</td>
									<td className='p-2'>{product.category}</td>
									<td className='p-2'>{product.available}</td>
									<td className='p-2'>{moment(product.createdAt).fromNow()}</td>
									<td className='p-2'>
										{' '}
										{moment(product.updatedAt).fromNow()}
									</td>
									<td className='flex p-2'>
										<Link to={`/products/view/${product._id}`}>
											<button className='bg-gray-400 px-2 rounded-sm focus:outline-none w-16'>
												View
											</button>
										</Link>
										<Link to={`/products/edit/${product._id}`}>
											<button className=' bg-green-600 text-white px-2 rounded-sm focus:outline-none ml-2 w-16'>
												Edit
											</button>
										</Link>
										<div
											className='cursor-pointer'
											onClick={() => {
												setShowDeleteModal(true);
												setDeleteName(product.name);
												setDeleteId(product._id);
											}}>
											<button className=' bg-red-600 text-white px-2 rounded-sm focus:outline-none ml-2 w-16'>
												Delete
											</button>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<div className='flex justify-between text-sm md:text-base mt-6'>
					<div>Total Record: {products.length}</div>
					<Pagination
						rowsPerPage={productsPerPage}
						totalRows={products.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</div>
			</div>
			{/* Modal */}
			{showDeleteModal && (
				<div
					className='bg-black bg-opacity-75 fixed h-full w-full top-0 left-0 transition flex items-center justify-center z-20'
					tabIndex='0'>
					<div className='p-6 md:p-5 h-auto flex flex-col w-1/2 lg:w-1/4 m-auto bg-white bg-opacity-100 overflow-auto relative'>
						<div className='text-center font-bold'>
							Are you sure you want to delete
							<span className='text-red-600'> {deleteName} </span>?
						</div>
						<div className='mt-6 text-center'>
							<button
								onClick={handleDelete}
								className='bg-red-600 text-white px-2 rounded-sm focus:outline-none ml-2 w-16'>
								Yes
							</button>
							<button
								onClick={() => {
									setShowDeleteModal(false);
								}}
								className='bg-gray-400 rounded-sm focus:outline-none w-16 ml-2'>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	products: selectProducts,
});

const mapDispatchToProps = (dispatch) => ({
	_fetchProductsAsync: () => dispatch(fetchProductsAsync()),
	_deleteProductAsync: (product_id) => dispatch(deleteProductAsync(product_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
