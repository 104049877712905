import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { client } from '../../client';
import './styles.scss';

const ViewOrder = () => {
	const [order, setOrder] = useState({});
	const { shippingDetails, courier, cartItems } = order;
	const params = useParams();

	useEffect(() => {
		(async () => {
			const orders = await client.service('orders').find({
				query: {
					_id: params.id,
				},
			});

			const [order] = orders.data;

			setOrder(order);
		})();
	}, []);

	const pluralize = (string) => {
		let plural = '';
		const stringArray = string.split('');

		stringArray.splice(-1, 1, '(', 's', ')');

		stringArray.forEach((letter) => {
			plural += letter;
		});

		return plural;
	};

	return (
		<div className='mx-10 lg:mx-20 xl:mx-32 flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/orders/list'>
					Orders
				</Link>{' '}
				{'>'} View Order
			</div>
			<div className='bg-white rounded-sm shadow-lg'>
				<div className='flex'>
					<div className='w-1/3 p-4 border-r border-gray-400'>
						<div className='text-center text-xs font-bold text-gray-700 uppercase tracking-wide mb-5'>
							Order Info
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Order ID:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								{order._id}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>User ID:</div>
							<div className='w-9/12 md:ml-4 text-sm text-blue-700'>
								<Link to={`/users/view/${order.user_id}`}>{order.user_id}</Link>
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Status:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								<mark
									className={`
									${order.status === 'processed' ? 'bg-orange-500 text-white' : ''}
									${order.status === 'confirmed' ? 'bg-blue-600 text-white' : ''} 
									${order.status === 'prepared' ? 'bg-yellow-400 text-gray-900' : ''} 
									${order.status === 'shipped' ? 'bg-teal-600 text-white' : ''}
									${order.status === 'delivered' ? 'bg-green-600 text-white' : ''} 
									${order.status === 'rejected' ? 'bg-red-600 text-white' : ''} 
									p-2  w-auto italic rounded-md`}>
									{order.status}
								</mark>
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Created At:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								{moment(order.createdAt).format('LLL')}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Updated At:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								{moment(order.updatedAt).format('LLL')}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Processed At:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								{moment(order.processedAt).format('LLL')}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Confirmed At:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								{order.confirmedAt
									? moment(order.confirmedAt).format('LLL')
									: 'N/A'}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Prepared At:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								{order.preparedAt
									? moment(order.preparedAt).format('LLL')
									: 'N/A'}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Shipped At:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								{order.shippedAt
									? moment(order.shippedAt).format('LLL')
									: 'N/A'}
							</div>
						</div>
						<div className='flex items-center mb-3 p-1 border-b border-gray-500'>
							<div className='w-3/12 text-sm font-bold'>Delivered At:</div>
							<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
								{order.deliveredAt
									? moment(order.deliveredAt).format('LLL')
									: 'N/A'}
							</div>
						</div>
					</div>
					<div className='w-1/3 p-4 border-l border-r border-gray-400'>
						<div className='text-center text-xs font-bold text-gray-700 uppercase tracking-wide mb-5'>
							Shipping Details
						</div>
						<div className='shipping-border' />
						<div className='p-2 shadow'>
							<div className='flex items-center mb-3 p-1'>
								<div className='w-3/12 text-sm font-bold'>Receiver:</div>
								<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
									{shippingDetails ? shippingDetails.name : ''}
								</div>
							</div>
							<div className='flex items-center mb-3 p-1'>
								<div className='w-3/12 text-sm font-bold'>Address:</div>
								<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
									{shippingDetails
										? `${shippingDetails.street}, ${shippingDetails.barangay}, ${shippingDetails.city}, ${shippingDetails.province} ( Landmark: ${shippingDetails.landmark} )`
										: ''}
								</div>
							</div>
							<div className='flex items-center mb-3 p-1'>
								<div className='w-3/12 text-sm font-bold'>Contact:</div>
								<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
									{shippingDetails ? shippingDetails.contact : ''}
								</div>
							</div>
							<div className='flex items-center mb-3 p-1'>
								<div className='w-3/12 text-sm font-bold'>Courier:</div>
								<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
									{courier && courier.name ? courier.name : 'N/A'}
								</div>
							</div>
							<div className='flex items-center mb-3 p-1'>
								<div className='w-3/12 text-sm font-bold'>Waybill No.:</div>
								<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
									{courier && courier.waybillNumber
										? courier.waybillNumber
										: 'N/A'}
								</div>
							</div>
							<div className='flex items-center mb-3 p-1'>
								<div className='w-3/12 text-sm font-bold'>Vessel:</div>
								<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
									{courier && courier.vessel ? courier.vessel : 'N/A'}
								</div>
							</div>
							<div className='flex items-center mb-3 p-1'>
								<div className='w-3/12 text-sm font-bold'>Voyage No.:</div>
								<div className='w-9/12 md:ml-4 text-sm text-gray-700'>
									{courier && courier.voyageNumber
										? courier.voyageNumber
										: 'N/A'}
								</div>
							</div>
						</div>
					</div>
					<div className='w-1/3 p-4 border-l border-gray-400'>
						<div className='text-center text-xs font-bold text-gray-700 uppercase tracking-wide mb-5'>
							Cart Details
						</div>
						<table className='table-fixed border-collapse w-full text-xs'>
							<thead className='bg-gray-300'>
								<tr className='text-left'>
									<th className='p-2 w-56'>Product ID</th>
									<th className='p-2 w-32'>Quantity</th>
									<th className='p-2 w-32'>Action</th>
								</tr>
							</thead>
							<tbody>
								{cartItems
									? cartItems.map((item, index) => {
											return (
												<tr key={index} className='tr text-sm'>
													<td className='p-2'>{item._id}</td>
													<td className='p-2'>
														{item.count} {pluralize(item.countType)}
													</td>
													<td className='p-2'>
														<Link to={`/products/view/${item._id}`}>
															<button className='bg-gray-400 px-2 rounded-sm focus:outline-none'>
																View
															</button>
														</Link>
													</td>
												</tr>
											);
									  })
									: null}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewOrder;
