import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useState } from 'react';
import moment from 'moment';

import Pagination from '../../components/pagination/pagination.component';
import {
	fetchUsersAsync,
	deleteUserAsync,
} from '../../redux/user/user.actions';
import { selectUsers } from '../../redux/user/user.selectors';

const Users = ({ users, _fetchUsersAsync, _deleteUserAsync }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteName, setDeleteName] = useState('');
	const [deleteId, setDeleteId] = useState('');
	const [searchField, setSearchField] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [usersPerPage] = useState(10);
	const filteredUsers = users.filter((user) => {
		return user.email.toLowerCase().includes(searchField.toLowerCase());
	});

	useEffect(() => {
		(async () => {
			await _fetchUsersAsync();
		})();
	}, []);

	const indexOfLastUser = currentPage * usersPerPage;
	const indexOfFirstUser = indexOfLastUser - usersPerPage;
	const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleChange = (e) => {
		setSearchField(e.target.value);
		setCurrentPage(1);
	};

	const handleDelete = async () => {
		await _deleteUserAsync(deleteId);
		setShowDeleteModal(false);
	};

	return (
		<div className='flex-grow py-4 px-10 md:px-20'>
			<div className='text-orange-500 font-bold text-3xl mb-2'>Users</div>
			<div className='bg-white rounded-sm shadow-lg p-3'>
				<div className='flex justify-between mt-3 mb-6'>
					<Link to='/users/add'>
						<button className='p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
							Add User
						</button>
					</Link>
					<div className='flex items-center'>
						<label htmlFor='search' className='mr-2'>
							Search:
						</label>
						<input
							className='py-1 px-2 outline-none rounded-md border border-gray-500'
							type='text'
							id='search'
							onChange={handleChange}
							placeholder='Email'
						/>
					</div>
				</div>
				<table className='table-fixed border-collapse w-full text-sm md:text-base'>
					<thead className='bg-gray-300'>
						<tr className='text-left'>
							<th className='p-2'>User ID</th>
							<th className='p-2'>Name</th>
							<th className='p-2'>Email</th>
							<th className='p-2 w-40'>Role</th>
							<th className='p-2 w-40'>Status</th>
							<th className='p-2 w-40'>Created At</th>
							<th className='p-2 w-40'>Updated At</th>
							<th className='p-2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{currentUsers.map((user, index) => {
							return (
								<tr key={index} className='tr text-sm'>
									<td className='p-2'>{user._id}</td>
									<td className='p-2 truncate'>{user.name}</td>
									<td className='p-2 truncate'>{user.email}</td>
									<td className='p-2'>{user.role}</td>
									<td className='p-2'>{user.status}</td>
									<td className='p-2'>{moment(user.createdAt).fromNow()}</td>
									<td className='p-2'>{moment(user.updatedAt).fromNow()}</td>
									<td className='flex p-2'>
										{/* <Link to={`/users/view/${user._id}`}>
											<button className='bg-gray-400 px-2 rounded-sm focus:outline-none w-16'>
												View
											</button>
										</Link> */}
										<Link to={`/users/edit/${user._id}`}>
											<button className=' bg-green-600 text-white px-2 rounded-sm focus:outline-none ml-2 w-16'>
												Edit
											</button>
										</Link>
										<div
											className='cursor-pointer'
											onClick={() => {
												setShowDeleteModal(true);
												setDeleteName(user.name);
												setDeleteId(user._id);
											}}>
											<button className=' bg-red-600 text-white px-2 rounded-sm focus:outline-none ml-2 w-16'>
												Delete
											</button>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<div className='flex justify-between text-sm md:text-base mt-6'>
					<div>Total Record: {users.length}</div>
					<Pagination
						rowsPerPage={usersPerPage}
						totalRows={users.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</div>
			</div>
			{/* Modal */}
			{showDeleteModal && (
				<div
					className='bg-black bg-opacity-75 fixed h-full w-full top-0 left-0 transition flex items-center justify-center z-20'
					tabIndex='0'>
					<div className='p-6 md:p-5 h-auto flex flex-col w-1/2 lg:w-1/4 m-auto bg-white bg-opacity-100 overflow-auto relative'>
						<div className='text-center font-bold'>
							Are you sure you want to delete
							<span className='text-red-600'> {deleteName} </span>?
						</div>
						<div className='mt-6 text-center'>
							<button
								onClick={handleDelete}
								className='bg-red-600 text-white px-2 rounded-sm focus:outline-none ml-2 w-16'>
								Yes
							</button>
							<button
								onClick={() => {
									setShowDeleteModal(false);
								}}
								className='bg-gray-400 rounded-sm focus:outline-none w-16 ml-2'>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	users: selectUsers,
});

const mapDispatchToProps = (dispatch) => ({
	_fetchUsersAsync: () => dispatch(fetchUsersAsync()),
	_deleteUserAsync: (user_id) => dispatch(deleteUserAsync(user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
