import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setCurrentUser } from '../../redux/user/user.actions';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { client } from '../../client';

import './navbar.styles.scss';

const Navbar = ({ currentUser, _setCurrentUser }) => {
	const [menu, toggleMenu] = useState(false);
	const menuRef = useRef(null);

	const handleToggleMenu = async () => {
		await new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, 150);
		});
		await toggleMenu(!menu);
		if (menuRef.current) {
			menuRef.current.focus();
		}
	};

	const handleLogout = async () => {
		await client.logout();

		_setCurrentUser(null);
	};

	const getFirstName = (fullName) => {
		if (fullName) {
			const names = fullName.split(' ');

			return names[0];
		}

		return fullName;
	};

	return (
		<div className='flex justify-between items-center bg-gray-900 text-white px-10 py-3 sticky top-0 z-10'>
			<div>
				<Link to='/dashboard' className='mr-4'>
					Dashboard
				</Link>

				<div className='inline-block relative mr-4 cursor-pointer'>
					<div
						className='flex items-center'
						tabIndex='0'
						onClick={() => handleToggleMenu()}>
						Orders
						<svg
							className='fill-current text-white pl-1'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 255 255 '
							width='15'
							height='15'>
							<path d='M0 63.75l127.5 127.5L255 63.75z' />
						</svg>
					</div>
					{menu && (
						<div
							className='dropdown-menu absolute bg-white text-gray-900 shadow-md pt-1'
							tabIndex='0'
							ref={menuRef}
							onBlur={() => handleToggleMenu()}>
							<Link
								to='/orders/list'
								className='rounded-t hover:bg-gray-300 py-2 px-4 block whitespace-no-wrap'>
								List
							</Link>
							<Link
								to='/orders/archived'
								className='rounded-t hover:bg-gray-300 py-2 px-4 block whitespace-no-wrap'>
								Archived
							</Link>
						</div>
					)}
				</div>
				<Link to='/products' className='mr-4'>
					Products
				</Link>
				<Link to='/users' className='mr-4'>
					Users
				</Link>
				<Link to='/couriers' className='mr-4'>
					Couriers
				</Link>
			</div>

			<div className='flex justify-between items-center'>
				<p className='mr-4'>
					Welcome Back {`${getFirstName(currentUser?.name)} !`}
				</p>
				<div
					className='flex items-center cursor-pointer'
					onClick={handleLogout}>
					<svg
						className='w-8 h-6 md:w-10 md:h-8'
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						stroke='#cbd5e0'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='1'
							d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
						/>
					</svg>
					<div className='text-gray-400'>Logout</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
