import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import { editUserAsync } from '../../redux/user/user.actions';

import { client } from '../../client';

const EditUser = ({ _editUserAsync }) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [status, setStatus] = useState('');
	const [role, setRole] = useState('');
	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			const users = await client.service('users').find({
				query: {
					_id: params.id,
				},
			});

			const [user] = users.data;

			setName(user.name);
			setEmail(user.email);
			setRole(user.role);
			setStatus(user.status);
		})();
	}, []);

	const handleChange = (event) => {
		const { name, value } = event.target;

		switch (name) {
			case 'name':
				setName(value);
				break;
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			case 'status':
				setStatus(value);
				break;
			default:
				return;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = {
			name,
			email,
			status,
		};

		if (password) {
			data.password = password;
		}

		_editUserAsync(params.id, data);

		history.push('/users');
	};

	const handleGenerate = () => {
		const generatePassword = () => {
			const length = 8;
			const charset =
				'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
			let retVal = '';
			let i;
			let n;

			for (i = 0, n = charset.length; i < length; ++i) {
				retVal += charset.charAt(Math.floor(Math.random() * n));
			}

			return retVal;
		};

		setPassword(generatePassword());
	};

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/users'>
					Users
				</Link>{' '}
				{'>'} Edit User
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4'>
				<p className='text-xs italic mb-2'>
					Note: Some fields are disabled to avoid unintentional change of data.
				</p>
				<form onSubmit={handleSubmit}>
					<div className='flex'>
						<div className='flex flex-col w-1/2 mr-2'>
							<label className='text-sm font-bold' htmlFor='name'>
								Full Name:
							</label>
							<input
								type='text'
								name='name'
								id='name'
								value={name}
								onChange={handleChange}
								required
								disabled={role === 'customer' ? true : false}
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
							<label className='text-sm font-bold' htmlFor='email'>
								Email:
							</label>
							<input
								type='email'
								name='email'
								id='email'
								value={email}
								onChange={handleChange}
								required
								disabled={role === 'customer' ? true : false}
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
							<div className='flex flex-col'>
								<label className='text-sm font-bold' htmlFor='password'>
									Password:
								</label>
								<div className='flex items-center'>
									<input
										type='text'
										name='password'
										id='password'
										placeholder='unchanged'
										value={password}
										minLength='6'
										disabled={role === 'customer' ? true : false}
										onChange={handleChange}
										className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 w-11/12'
									/>
									<button
										type='button'
										className='flex justify-center bg-gray-400 p-2 rounded-sm focus:outline-none -ml-1 w-1/12'
										disabled={role === 'customer' ? true : false}
										onClick={handleGenerate}>
										<svg
											className='w-5 h-5'
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
											/>
										</svg>
									</button>
								</div>
							</div>
						</div>
						<div className='flex flex-col w-1/2 ml-2'>
							<label className='text-sm font-bold' htmlFor='role'>
								Role:
							</label>
							<input
								type='text'
								name='role'
								id='role'
								value={role}
								onChange={handleChange}
								disabled
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
							<label className='text-sm font-bold' htmlFor='status'>
								Status:
							</label>
							<select
								name='status'
								id='status'
								value={status}
								onChange={handleChange}
								className='text-sm py-1 px-2 outline-none rounded-md border border-gray-500 bg-white mb-4 h-8'>
								<option className='border-gray-500' value='active'>
									active
								</option>
								<option className='border-gray-500' value='disabled'>
									disabled
								</option>
							</select>
						</div>
					</div>
					<div className='flex justify-center mt-6'>
						<button
							type='submit'
							className='w-1/4 p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
							Save
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	_editUserAsync: (_id, data) => dispatch(editUserAsync(_id, data)),
});

export default connect(null, mapDispatchToProps)(EditUser);
