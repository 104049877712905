import { createSelector } from 'reselect';

const selectProduct = (state) => state.product;

export const selectProductCount = createSelector(
	[selectProduct],
	(product) => product.productCount
);

export const selectProducts = createSelector(
	[selectProduct],
	(product) => product.productItems
);
