import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { addCourierAsync } from '../../redux/courier/courier.actions';

const AddCourier = ({ _addCourierAsync }) => {
	const [name, setName] = useState('');
	const history = useHistory();

	const handleChange = (event) => {
		const { name, value } = event.target;

		switch (name) {
			case 'name':
				setName(value);
				break;
			default:
				return;
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		await _addCourierAsync({
			name,
		});

		history.push('/couriers');
	};

	return (
		<div className='container flex-grow py-4'>
			<div className='mb-2'>
				<Link className='hover:underline' to='/couriers'>
					Couriers
				</Link>{' '}
				{'>'} Add Courier
			</div>
			<div className='bg-white rounded-sm shadow-lg p-4 w-1/2'>
				<form onSubmit={handleSubmit}>
					<div className='flex flex-col'>
						<div className='flex flex-col w-full mr-2'>
							<label htmlFor='name'>Courier Name:</label>
							<input
								type='text'
								name='name'
								id='name'
								value={name}
								onChange={handleChange}
								required
								className='py-1 px-2 outline-none rounded-md border border-gray-500 mb-4'
							/>
						</div>
						<div className='flex justify-center mt-6'>
							<button
								type='submit'
								className='w-1/4 p-2 bg-blue-500 text-white font-semibold tracking-wide rounded-sm focus:outline-none'>
								Submit
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	_addCourierAsync: (data) => dispatch(addCourierAsync(data)),
});

export default connect(null, mapDispatchToProps)(AddCourier);
