import { productActionTypes } from './product.types';

const INITIAL_STATE = {
	productCount: 0,
	productItems: [],
	isFetching: false,
};

const productReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case productActionTypes.FETCH_PRODUCTS_START:
			return {
				...state,
				isFetching: true,
			};
		case productActionTypes.SET_PRODUCTS:
			return {
				...state,
				productItems: action.payload,
				isFetching: false,
			};
		case productActionTypes.SET_PRODUCT_COUNT:
			return {
				...state,
				productCount: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};

export default productReducer;
