import { userActionTypes } from './user.types';
import { client } from '../../client';

const fetchStart = () => ({
	type: userActionTypes.FETCH_START,
});

const setUsers = (users) => ({
	type: userActionTypes.SET_USERS,
	payload: users,
});

export const setUserCount = (userCount) => ({
	type: userActionTypes.SET_USER_COUNT,
	payload: userCount,
});

export const setCurrentUser = (user) => ({
	type: userActionTypes.SET_CURRENT_USER,
	payload: user,
});

export const setErrorMessage = (message) => ({
	type: userActionTypes.SET_ERROR_MESSAGE,
	payload: message,
});

export const fetchUserCountAsync = () => {
	return async (dispatch) => {
		try {
			const userCount = await client.service('users').find({
				query: {
					$limit: 0,
				},
			});

			dispatch(setUserCount(userCount.total));
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const loginAsync = (email, password) => {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			const auth = await client.authenticate({
				strategy: 'local',
				client: 'admin',
				email,
				password,
			});
			await dispatch(setCurrentUser(auth.user));
			dispatch(setErrorMessage(null));
		} catch (e) {
			const errorMessage =
				e.code === 401
					? e.message
					: 'Something went wrong. Please try again later.';

			dispatch(setErrorMessage(errorMessage));
		}
	};
};

export const reauthAsync = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			const auth = await client.reAuthenticate();
			await dispatch(setCurrentUser(auth.user));
		} catch (e) {
			const errorMessage =
				e.code === 401 ? null : 'Something went wrong. Please try again later.';

			dispatch(setErrorMessage(errorMessage));
		}
	};
};

export const fetchUsersAsync = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			const users = await client.service('users').find({
				query: {
					$paginate: { value: false },
					$sort: {
						name: 1,
					},
				},
			});

			await dispatch(setUsers(users));
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const createUserAsync = (data) => {
	return async (dispatch) => {
		try {
			await client.service('users').create(data);
			dispatch(fetchUsersAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const editUserAsync = (_id, data) => {
	return async (dispatch) => {
		try {
			await client.service('users').patch(_id, data);
			dispatch(fetchUsersAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const deleteUserAsync = (user_id) => {
	return async (dispatch) => {
		try {
			await client.service('users').remove(user_id);
			dispatch(fetchUsersAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};
