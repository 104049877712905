import { productActionTypes } from './product.types';
import { client } from '../../client';

const fetchProductsStart = () => ({
	type: productActionTypes.FETCH_PRODUCTS_START,
});

const setProducts = (products) => ({
	type: productActionTypes.SET_PRODUCTS,
	payload: products,
});

export const setProductCount = (product) => ({
	type: productActionTypes.SET_PRODUCT_COUNT,
	payload: product,
});

export const fetchProductCountAsync = () => {
	return async (dispatch) => {
		try {
			const productCount = await client.service('products').find({
				query: {
					$limit: 0,
				},
			});

			dispatch(setProductCount(productCount.total));
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const fetchProductsAsync = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchProductsStart());
			const products = await client.service('products').find({
				query: {
					$paginate: { value: false },
					$sort: {
						name: 1,
					},
				},
			});

			await dispatch(setProducts(products));
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const addProductAsync = (data) => {
	return async (dispatch) => {
		try {
			await client.service('products').create(data);
			dispatch(fetchProductsAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const editProductAsync = (_id, data) => {
	return async (dispatch) => {
		try {
			await client.service('products').patch(_id, data);
			dispatch(fetchProductsAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const deleteProductAsync = (product_id) => {
	return async (dispatch) => {
		try {
			await client.service('products').remove(product_id);
			dispatch(fetchProductsAsync());
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};
