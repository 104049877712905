import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import Pagination from '../../components/pagination/pagination.component';
import { fetchOrdersAsync } from '../../redux/order/order.actions';
import { selectOrders } from '../../redux/order/order.selectors';

import { client } from '../../client';

const OrdersList = ({ _fetchOrdersAsync, orders }) => {
	const [showRejectModal, setShowRejectModal] = useState(false);
	const [deleteId, setDeleteId] = useState('');
	const [searchField, setSearchField] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [ordersPerPage] = useState(10);
	const filteredOrders = orders.filter((order) => {
		return order._id.toLowerCase().includes(searchField.toLowerCase());
	});

	useEffect(() => {
		(async () => {
			await _fetchOrdersAsync();
		})();
	}, []);

	const indexOfLastOrder = currentPage * ordersPerPage;
	const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
	const currentOrders = filteredOrders.slice(
		indexOfFirstOrder,
		indexOfLastOrder
	);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleChange = (e) => {
		setSearchField(e.target.value);
		setCurrentPage(1);
	};

	const handleArchive = async (nextProccess, order_id) => {
		if (nextProccess !== 'archive') {
			return;
		}

		await client.service('orders').patch(order_id, {
			isArchived: true,
		});

		_fetchOrdersAsync();
	};

	const handleDelete = async () => {
		await client.service('orders').patch(deleteId, {
			isArchived: true,
			status: 'rejected',
		});

		await _fetchOrdersAsync();
		setShowRejectModal(false);
	};

	return (
		<div className='flex-grow py-4 px-10 md:px-20'>
			<div className='text-teal-500 font-bold text-3xl mb-2'>Orders</div>
			<div className='bg-white rounded-sm shadow-lg p-3'>
				<div className='flex justify-between mt-3 mb-6'>
					<div>{''}</div>
					<div className='flex items-center'>
						<label htmlFor='search' className='mr-2'>
							Search:
						</label>
						<input
							className='py-1 px-2 outline-none rounded-md border border-gray-500'
							type='text'
							id='search'
							onChange={handleChange}
							placeholder='Order ID'
						/>
					</div>
				</div>
				<table className='table-fixed border-collapse w-full text-sm md:text-base'>
					<thead className='bg-gray-300'>
						<tr className='text-left'>
							<th className='p-2'>Order ID</th>
							<th className='p-2'>Customer Name</th>
							<th className='p-2 w-40'>Cart Items</th>
							<th className='p-2 w-40'>Status</th>
							<th className='p-2'>Courier</th>
							<th className='p-2'>Processed At</th>
							<th className='p-2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{currentOrders.map((order, index) => {
							let nextProccess;

							switch (order.status) {
								case 'processed':
									nextProccess = 'confirm';
									break;
								case 'confirmed':
									nextProccess = 'prepare';
									break;
								case 'prepared':
									nextProccess = 'ship';
									break;
								case 'shipped':
									nextProccess = 'deliver';
									break;
								case 'delivered':
									nextProccess = 'archive';
									break;
								default:
									return;
							}

							return (
								<tr key={index} className='tr text-sm'>
									<td className='p-2'>{order._id}</td>
									<td className='p-2 text-blue-700'>
										<Link to={`/users/view/${order.user_id}`}>
											{order.user_name}
										</Link>
									</td>
									<td className='p-2'>{order.cartItems.length}</td>
									<td className='p-2'>
										<mark
											className={`
										${order.status === 'processed' ? 'bg-orange-500 text-white' : ''}
											${order.status === 'confirmed' ? 'bg-blue-600 text-white' : ''}
											${order.status === 'prepared' ? 'bg-yellow-400 text-gray-900' : ''}
											${order.status === 'shipped' ? 'bg-teal-600 text-white' : ''} 
											${order.status === 'delivered' ? 'bg-green-600 text-white' : ''} 
											p-2 w-auto italic rounded-md`}>
											{order.status}
										</mark>
									</td>
									<td className='p-2'>
										{order.courier.name ? order.courier.name : ''}
									</td>
									<td className='p-2'>{moment(order.processedAt).fromNow()}</td>
									<td className='flex p-2'>
										<Link to={`/orders/view/${order._id}`}>
											<button className='bg-gray-400 px-2 rounded-sm focus:outline-none w-16'>
												View
											</button>
										</Link>
										{nextProccess === 'deliver' && (
											<Link to={`/orders/ship/edit/${order._id}`}>
												<button className='bg-white border border-gray-400 px-2 rounded-sm focus:outline-none w-16 ml-2'>
													Edit
												</button>
											</Link>
										)}
										<Link
											to={
												nextProccess === 'archive'
													? '#'
													: `/orders/${nextProccess}/${order._id}`
											}>
											<button
												onClick={() => {
													handleArchive(nextProccess, order._id);
												}}
												className={`
												${nextProccess === 'confirm' ? ' bg-blue-600 text-white' : null}
												${nextProccess === 'prepare' ? 'bg-yellow-400 text-gray-900' : null}
												${nextProccess === 'ship' ? 'bg-teal-600 text-white' : null}
												${nextProccess === 'deliver' ? 'bg-green-600 text-white' : null}
												${nextProccess === 'archive' ? 'bg-red-600 text-white' : null}
												px-2 rounded-sm focus:outline-none capitalize w-16 ml-2`}>
												{nextProccess}
											</button>
										</Link>

										{nextProccess === 'confirm' && (
											<div
												className='cursor-pointer'
												onClick={() => {
													setShowRejectModal(true);
													setDeleteId(order._id);
												}}>
												<button className=' bg-red-600 text-white px-2 rounded-sm focus:outline-none ml-2 w-16'>
													Reject
												</button>
											</div>
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<div className='flex justify-between text-sm md:text-base mt-6'>
					<div>Total Record: {orders.length}</div>
					<Pagination
						rowsPerPage={ordersPerPage}
						totalRows={orders.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</div>
			</div>
			{/* Modal */}
			{showRejectModal && (
				<div
					className='bg-black bg-opacity-75 fixed h-full w-full top-0 left-0 transition flex items-center justify-center z-20'
					tabIndex='0'>
					<div className='p-6 md:p-5 h-auto flex flex-col w-1/2 lg:w-1/4 m-auto bg-white bg-opacity-100 overflow-auto relative'>
						<div className='text-center font-bold'>
							<p> Are you sure you want to reject this order?</p>
							<p className='font-light italic'>
								The customer will receive an email regarding this action.
							</p>
						</div>
						<div className='mt-6 text-center'>
							<button
								onClick={handleDelete}
								className='bg-red-600 text-white px-2 rounded-sm focus:outline-none ml-2 w-16'>
								Yes
							</button>
							<button
								onClick={() => {
									setShowRejectModal(false);
								}}
								className='bg-gray-400 rounded-sm focus:outline-none w-16 ml-2'>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	orders: selectOrders,
});

const mapDispatchToProps = (dispatch) => ({
	_fetchOrdersAsync: () => dispatch(fetchOrdersAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
