import React, { useEffect, useState, useRef } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Navbar from './components/navbar/navbar.component';
import Login from './pages/login/login.component';
import Dashboard from './pages/dashboard/dashboard.component';
import Users from './pages/users/users.component';
import AddUser from './pages/add-user/add-user.component';
import EditUser from './pages/edit-user/edit-user.component';
import ViewUser from './pages/view-user/view-user.component';
import OrdersList from './pages/orders/orders-list.component';
import OrdersArchived from './pages/orders/orders-archived.component';
import ViewOrder from './pages/view-order/view-order.component';
import ConfirmOrder from './pages/confirm-order/confirm-order.component';
import PrepareOrder from './pages/prepare-order/prepare-order.component';
import ShipOrder from './pages/ship-order/ship-order.component';
import EditShipOrder from './pages/edit-ship-order/edit-ship-order.component';
import DeliverOrder from './pages/deliver-order/deliver-order.component';
import Products from './pages/products/products.component';
import AddProduct from './pages/add-product/add-product.component';
import ViewProduct from './pages/view-product/view-product.component';
import EditProduct from './pages/edit-product/edit-product.component';
import Couriers from './pages/couriers/couriers.component';
import AddCourier from './pages/add-courier/add-courier.component';
import EditCourier from './pages/edit-courier/edit-courier.component';
import PageNotFound from './components/page-not-found/page-not-found.component';

import PrivateRoute from './routes/private-route';

import { reauthAsync } from './redux/user/user.actions';
import { selectCurrentUser } from './redux/user/user.selectors';

function App({ currentUser, _reauthAsync }) {
	const [isLoaded, setIsLoaded] = useState(false);
	const didMountRef = useRef(false);
	const location = useLocation();
	const { pathname } = location;

	useEffect(() => {
		if (didMountRef.current) {
			// DID UPDATE
			setIsLoaded(true);
		} else {
			// DID MOUNT
			didMountRef.current = true;
			(async () => {
				await _reauthAsync();
			})();
		}
	});

	return (
		<div className='antialiased text-gray-900 bg-gray-100 min-h-screen flex flex-col '>
			{pathname !== '/login' ? <Navbar /> : null}
			<Switch>
				<Route exact path='/' render={() => <Redirect to='/login' />} />
				<Route
					exact
					path='/login'
					render={(props) => {
						const { state } = props.location;
						return currentUser ? (
							<Redirect to={state ? state.from.pathname : '/dashboard'} />
						) : (
							<Login />
						);
					}}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/dashboard'
					component={Dashboard}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/users'
					component={Users}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/users/add'
					component={AddUser}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/users/view/:id'
					component={ViewUser}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/users/edit/:id'
					component={EditUser}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders/list'
					component={OrdersList}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders/archived'
					component={OrdersArchived}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders/view/:id'
					component={ViewOrder}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders/confirm/:id'
					component={ConfirmOrder}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders/prepare/:id'
					component={PrepareOrder}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders/ship/:id'
					component={ShipOrder}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders/ship/edit/:id'
					component={EditShipOrder}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders/deliver/:id'
					component={DeliverOrder}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/products'
					component={Products}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/products/add'
					component={AddProduct}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/products/view/:id'
					component={ViewProduct}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/products/edit/:id'
					component={EditProduct}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/couriers'
					component={Couriers}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/couriers/add'
					component={AddCourier}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/couriers/edit/:id'
					component={EditCourier}
				/>
				<Route path='*' component={PageNotFound} />
			</Switch>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_reauthAsync: () => dispatch(reauthAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
