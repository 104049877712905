import { courierActionTypes } from './courier.types';

const INITIAL_STATE = {
	courierItems: [],
	isFetching: false,
};

const courierReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case courierActionTypes.FETCH_COURIERS_START:
			return {
				...state,
				isFetching: true,
			};
		case courierActionTypes.SET_COURIERS:
			return {
				...state,
				courierItems: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default courierReducer;
