import { orderActionTypes } from './order.types';

import { client } from '../../client';

const fetchOrdersStart = () => ({
	type: orderActionTypes.FETCH_ORDERS_START,
});

const setOrders = (orders) => ({
	type: orderActionTypes.SET_ORDERS,
	payload: orders,
});

const setArchivedOrders = (archivedOrders) => ({
	type: orderActionTypes.SET_ARCHIVED_ORDERS,
	payload: archivedOrders,
});

export const setOrderCount = (orderCount) => ({
	type: orderActionTypes.SET_ORDER_COUNT,
	payload: orderCount,
});

export const fetchOrderCountAsync = () => {
	return async (dispatch) => {
		try {
			const orderCount = await client.service('orders').find({
				query: {
					isArchived: false,
					$limit: 0,
				},
			});

			dispatch(setOrderCount(orderCount.total));
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const fetchOrdersAsync = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchOrdersStart());
			const orders = await client.service('orders').find({
				query: {
					isArchived: false,
					$paginate: { value: false },
					$sort: {
						createdAt: 1,
					},
				},
			});

			await dispatch(setOrders(orders));
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};

export const fetchArchivedOrdersAsync = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchOrdersStart());
			const archivedOrders = await client.service('orders').find({
				query: {
					isArchived: true,
					$paginate: { value: false },
					$sort: {
						createdAt: 1,
					},
				},
			});

			await dispatch(setArchivedOrders(archivedOrders));
		} catch (e) {
			console.log(e); // TODO: handle laters
		}
	};
};
