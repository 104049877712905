import { userActionTypes } from './user.types';

const INITIAL_STATE = {
	userCount: 0,
	users: [],
	isFetching: false,
	currentUser: null,
	errorMessage: '',
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case userActionTypes.SET_USER_COUNT:
			return {
				...state,
				userCount: action.payload,
			};
		case userActionTypes.SET_USERS:
			return {
				...state,
				users: action.payload,
				isFetching: false,
			};
		case userActionTypes.FETCH_START:
			return {
				...state,
				isFetching: true,
			};
		case userActionTypes.SET_CURRENT_USER:
			return {
				...state,
				currentUser: action.payload,
				isFetching: false,
			};
		case userActionTypes.SET_ERROR_MESSAGE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default userReducer;
