import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { loginAsync, setErrorMessage } from '../../redux/user/user.actions';
import { selectErrorMessage } from '../../redux/user/user.selectors';

import Logo from '../../assets/images/logo.png';

const Login = ({ _loginAsync, errorMessage }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const handleChange = (event) => {
		const { name, value } = event.target;

		switch (name) {
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			default:
				return;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		_loginAsync(email, password);
	};

	return (
		<div className='flex-grow bg-gray-800'>
			<div className='flex flex-col items-center mt-24 sm:mt-32'>
				<img className='h-20 md:h-24' src={Logo} alt='logo' />
				<form className='w-3/4 lg:w-1/2 xl:w-1/3' onSubmit={handleSubmit}>
					<div className='flex flex-col items-center bg-white mt-5 py-8 px-8 md:px-10'>
						<div className='font-bold text-brand-color sm:text-xl lg:text-2xl mb-2'>
							Welcome Admin
						</div>
						<div className='my-3 text-red-600 self-start'>{errorMessage}</div>
						<input
							className='w-full h-8 md:h-10 mb-4 py-2 px-4 outline-none rounded-md border border-gray-500'
							type='email'
							name='email'
							placeholder='email'
							value={email}
							onChange={handleChange}
							required
						/>
						<input
							className='w-full h-8 md:h-10 mb-4 py-2 px-4 outline-none rounded-md border border-gray-500'
							type='password'
							name='password'
							placeholder='password'
							value={password}
							onChange={handleChange}
							required
						/>
						<button
							className='w-1/2 bg-brand-color text-white rounded-md p-3 mt-2 focus:outline-none'
							type='submit'>
							Log In
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	errorMessage: selectErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
	_loginAsync: (email, password) => dispatch(loginAsync(email, password)),
	_setErrorMessage: (message) => dispatch(setErrorMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
