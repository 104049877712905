import { orderActionTypes } from './order.types';

const INITIAL_STATE = {
	orderCount: 0,
	orderItems: [],
	archivedOrderItems: [],
	isFetching: false,
};

const orderReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case orderActionTypes.FETCH_ORDERS_START:
			return {
				...state,
				isFetching: true,
			};
		case orderActionTypes.SET_ORDERS:
			return {
				...state,
				orderItems: action.payload,
				isFetching: false,
			};
		case orderActionTypes.SET_ARCHIVED_ORDERS:
			return {
				...state,
				archivedOrderItems: action.payload,
				isFetching: false,
			};
		case orderActionTypes.SET_ORDER_COUNT:
			return {
				...state,
				orderCount: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};

export default orderReducer;
